import React from "react";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const CityPage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/CityPage"));
const PrivilegePage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/PrivilegePage"));
const CarBrandPage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/CarBrandPage"));
const CarModelPage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/CarModelPage"));
const CarTypePage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/CarTypePage"));
const FranchisePage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/FranchisePage"));
const VoluntaryInsurancePage = React.lazy(() => import("~/Containers/OscpvSettingSection/ListContainer/Pages/VoluntaryInsurancePage"));

const EmployeesPage = React.lazy(() => import("~/Containers/SettingSection/EmployeeContainer/Pages/EmployeesPageComponent"));

import SetSectionMenuTask from "../Tasks/SetSectionMenuTask";

export const OscpvSettingRoute = [
    {
        path: '/oscpv-setting',
        element: <DefaultLayout />,
        loader: () => {
            SetSectionMenuTask.run();
            return null;
        },
        children: [
            { index: true, element: <React.Suspense fallback={<>...</>}><EmployeesPage /></React.Suspense> },
            { path: 'list', children: [
                { path: 'city', element: <React.Suspense fallback={<>...</>}><CityPage /></React.Suspense> },
                { path: 'privilege', element: <React.Suspense fallback={<>...</>}><PrivilegePage /></React.Suspense> },
                { path: 'car-brand', element: <React.Suspense fallback={<>...</>}><CarBrandPage /></React.Suspense> },
                { path: 'car-model', element: <React.Suspense fallback={<>...</>}><CarModelPage /></React.Suspense> },
                { path: 'car-type', element: <React.Suspense fallback={<>...</>}><CarTypePage /></React.Suspense> },
                { path: 'franchise', element: <React.Suspense fallback={<>...</>}><FranchisePage /></React.Suspense> },
                { path: 'voluntary-insurance', element: <React.Suspense fallback={<>...</>}><VoluntaryInsurancePage /></React.Suspense> }
            ] }
        ]
    }
];