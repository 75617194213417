import { AiOutlineCalculator } from "react-icons/ai";
import { IoMdContacts } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlinePayment, MdOutlinePhoneInTalk } from "react-icons/md";
import { BsBriefcase, BsCurrencyDollar } from "react-icons/bs";
import { AiFillCar } from "react-icons/ai";
import { TbCalendar } from "react-icons/tb";
import { FaTasks } from "react-icons/fa";
import { observer } from "mobx-react-lite";
import { Badge } from "antd";

import CounterStore from "~/Ship/Stores/CounterStore";

const TaskLabel = observer(() => <>Задачі&nbsp;&nbsp;<Badge count={CounterStore.taskCreated} />&nbsp;</>);

export const MenuItems = [
    {
        key: 'task',
        label: <TaskLabel />,
        icon: <FaTasks size='20' />,
        path: '/oscpv/task'
    },
    {
        key: 'calculation',
        label: 'Розрахунки ОСЦПВ',
        icon: <AiOutlineCalculator size='20' />,
        path: '/oscpv/calculation'
    },
    {
        key: 'contract',
        label: 'Договори ОСЦПВ',
        icon: <IoDocumentTextOutline size='20' />,
        path: '/oscpv/contract'
    },
    {
        key: 'payment',
        label: 'Оплати ОСЦПВ',
        icon: <MdOutlinePayment size='20' />,
        path: '/oscpv/payment'
    },
    {
        key: 'client',
        label: 'Клієнти ОСЦПВ',
        icon: <IoMdContacts size='20' />,
        path: '/oscpv/client'
    },
    {
        key: 'reminder',
        label: 'Нагадування ОСЦПВ',
        icon: <TbCalendar size='20' />,
        path: '/oscpv/reminder'
    },
    {
        key: 'car',
        label: 'Автомобілі',
        icon: <AiFillCar size='20' />,
        path: '/oscpv/car'
    },
    {
        key: 'insurance_case',
        label: 'Страхові випадки',
        icon: <BsBriefcase size='20' />,
        path: '/oscpv/insurance-case'
    },
    {
        key: 'calls',
        label: 'Дзвінки',
        icon: <MdOutlinePhoneInTalk size='20' />,
        path: '/oscpv/calls'
    },
    {
        key: 'bonuses',
        label: 'Бонуси',
        icon: <BsCurrencyDollar size='20' />,
        path: '/oscpv/bonuses'
    }
];