import {
    useLocation,
    useNavigate,
    useParams,
    useLoaderData
} from "react-router-dom";

export const RouterDecorator = (Component) => {
    
    function ComponentWithRouterProp(props) {

        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const loaderData = useLoaderData();

        return (
            <Component
                {...props}
                router={{ location, navigate, params, loaderData }}
            />
        );
    }

    return ComponentWithRouterProp;
}