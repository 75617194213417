import React from "react";
import { AuthLayout } from "~/Ship/Layouts/AuthLayout";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const SigninPage = React.lazy(() => import("~/Containers/CoreSection/SigninContainer/Pages/SigninPage"));
const ForgotPasswordPage = React.lazy(() => import("~/Containers/CoreSection/ForgotPasswordContainer/Pages/ForgotPasswordPage"));
const ForgotPasswordFinishPage = React.lazy(() => import('~/Containers/CoreSection/ForgotPasswordContainer/Pages/ForgotPasswordFinishPage'));
const HomePage = React.lazy(() => import("~/Containers/CoreSection/HomeContainer/Pages/HomePage"));
const NotFoundPage = React.lazy(() => import("~/Containers/CoreSection/ErrorContainer/Pages/NotFoundPage"));

export const CoreRoute = [
    {
        path: '/',
        element: <DefaultLayout />,
        children: [{ index: true, element: <React.Suspense fallback={<>...</>}><HomePage /></React.Suspense>}]
    },
    {
        path: 'public',
        element: <AuthLayout />,
        children: [
            {
                path: 'signin',
                element: <React.Suspense fallback={<>...</>}><SigninPage /></React.Suspense>
            },
            {
                path: 'forgot',
                element: <React.Suspense fallback={<>...</>}><ForgotPasswordPage /></React.Suspense>
            },
            {
                path: 'finish-forgot-password/:token',
                element: <React.Suspense fallback={<>...</>}><ForgotPasswordFinishPage /></React.Suspense>
            }
        ]
    },
    { 
        path: '*',
        element: <React.Suspense fallback={<>...</>}><NotFoundPage /></React.Suspense>
    }
];