import GlobalPlace from '~/Core/Stores/GlobalPlaceStore';
import SidebarMenu from '~/Core/Components/SidebarMenu';
import { MenuItems } from '../Data/MenuItems';

class SetSectionMenuTask {
    run() {
        GlobalPlace.addToPlace('place-1', 'sidebarmenu', <SidebarMenu items={MenuItems} />);
    }
}

export default new SetSectionMenuTask();