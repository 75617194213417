import React from 'react';
import {
    useNavigate,
    useLocation,
    Navigate
} from 'react-router-dom';

export const AuthContext = React.createContext(null);

// import {AuthContext} from '~/Core/Providers/ProtectedProvider';

const useAuth = () => {
    let token = localStorage.getItem('token');
    
    if(token == 'null')
        token = null;
        
    // return React.useContext(AuthContext);
    return {token};
};

const ProtectedRouteHelper = ({ children }) => {
    

    return children;
};

export {ProtectedRouteHelper};

const checkAuth = () => {

  const { token } = useAuth();
  const user = localStorage.getItem('user');
  
  if (!token || !user ||  user == 'undefined')
    return false;

  return true;

}

const ProtectedProvider = ({ children }) => {

  const location = useLocation();

  if( !checkAuth() )
  {
    if (location.pathname !== '/public/signin')
      localStorage.setItem('redirect', location.pathname);
    else
      localStorage.setItem('redirect', '');

    return <Navigate to="/public/signin" replace />;
  }
  else
  {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    
    if( !user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_READONLY') && location.pathname != '/easypeasy/equipment-application') 
      return <Navigate to="/easypeasy/equipment-application" replace />;

    const [token, setToken] = React.useState(null);

    const handleLogin = async () => {
      const token = await fakeAuth();

      setToken(token);
      // TODO: check path
      const origin = location.state?.from?.pathname || '/dashboard';
      navigate(origin);
    };

    const handleLogout = () => {
      setToken(null);
    };

    const value = {
      token,
      onLogin: handleLogin,
      onLogout: handleLogout,
    };

    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  }
};

export { ProtectedProvider };