import React from 'react'
import ReactDOM from 'react-dom/client'

import { router } from "~/Ship/Router";
import { RouterProvider } from "react-router-dom";
import { Spin } from 'antd';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={ <Spin /> } />
  </React.StrictMode>
)
