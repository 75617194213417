import React, { useState } from 'react';
import {Avatar, Form, Col, Input, Button, notification, Row} from 'antd';

import {useDropzone} from 'react-dropzone';
import './index.style.less';
import { observer } from 'mobx-react-lite';

import RequestClient from '~/Core/Helpers/RequestClientHelper';
import EmployeeStore from "~/Ship/Stores/EmployeeStore";
import Config from "~/Ship/Config/Backend";
import { toJS } from 'mobx';

const PersonalInfo = () =>
{
  const [userImage, setUserImage] = useState('/assets/images/no-profile-picture-icon.png');
  const [formRef] = Form.useForm();

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      let reader = new FileReader();

      reader.onload = e => {
        const user = EmployeeStore.me;
        RequestClient.post('/api/company/employee/set_avatar_by_base64', {
          file: e.target.result.replace('data:image/jpeg;base64,', ''),
          name: acceptedFiles[0].name
        }).then(result => {
          formRef.setFieldValue('photo', result.photoUrl);
          const user = EmployeeStore.me;
          user.photo = result.photoUrl;
          EmployeeStore.setMe(user);
        });
      }
      
      reader.readAsDataURL(acceptedFiles[0]);

      setUserImage(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const onReset = () => {
    setUserImage(Config.API_URL + '/assets/images/no-profile-picture-icon.png');
    const user = EmployeeStore.me;
    user.photo = '';
    EmployeeStore.setMe(user);
    RequestClient.patch(`/api/company/employees/${user.id}`, {photoUrl: null}).then(() => {
      notification.success({
        message: 'Оновлення інформації',
        description: 'Інформація успішно оновлена',
        placement: 'top',
      });
    });
  };

  const onFinish = (values) => {
    const user = EmployeeStore.me;

    Object.keys(values).map(key => {
      if(user[key])
        user[key] = values[key];
    });

    EmployeeStore.setMe(user);

    RequestClient.patch(`/api/company/employees/${user.id}`, values).then(() => {
      notification.success({
        message: 'Оновлення інформації',
        description: 'Інформація успішно оновлена',
        placement: 'top',
      });
    });
  };

  const employee = toJS(EmployeeStore.me);

  useState(() => {
    if( employee.photo )
      setUserImage( Config.API_URL + employee.photo );
  }, []);

  return (
    <Form
      onFinish={onFinish}
      form={formRef}
      name="profile-form"
      initialValues={employee}
    >
      <Form.Item className='info-upload' name="photo">
        <Avatar className='info-upload-avatar' src={userImage} />
        <div className='info-upload-content'>
          <div className='info-upload-btn-group'>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()}/>
              <label htmlFor='icon-button-file'>
                <Button type='primary'>Завантажити</Button>
              </label>
            </div>
            <Button onClick={onReset}>Скинути</Button>
          </div>
          <p>Дозволено JPG, GIF або PNG. Максимальний розмір 800 КБ</p>
        </div>
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='name'
            rules={[{required: true, message: 'Поле обов\'язкове для заповнення!'}]}>
            <Input placeholder="Ім'я" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='lastName'
            rules={[{required: true, message: 'Поле обов\'язкове для заповнення!'}]}>
            <Input placeholder="Прізвище" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='patronymic'
          >
            <Input placeholder="По-батькові" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='email'
            rules={[
              {required: true, message: 'Поле обов\'язкове для заповнення!'},
            ]}>
            <Input type='text' placeholder='E-mail' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='internalPhone'
          >
            <Input type='text' placeholder='Внутрішній номер телефону' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='position'
          >
            <Input type='text' placeholder='Посада' />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item shouldUpdate className='user-profile-group-btn'>
            <Button type='primary' htmlType='submit'>
              Зберегти зміни
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default observer(PersonalInfo);
