import { createBrowserRouter } from "react-router-dom";

import { CoreRoute } from "~/Containers/CoreSection/IndexContainer/Routes/CoreRoute";
import { EasyPeasyRoute } from "~/Containers/EasyPeasySection/IndexContainer/Routes/EasyPeasyRoute";
import { SettingRoute } from "~/Containers/SettingSection/IndexContainer/Routes/SettingRoute";
import { OscpvRoute } from "~/Containers/OscpvSection/IndexContainer/Routes/OscpvRoute";
import { OscpvSettingRoute } from "~/Containers/OscpvSettingSection/IndexContainer/Routes/OscpvSettingRoute";
import { ServiceRoute } from "~/Containers/ServiceSection/IndexContainer/Routes/ServiceRoute";

import DemoPage from "~/Ship/DemoPage";

export const router = createBrowserRouter([
    ...CoreRoute,
    ...EasyPeasyRoute,
    ...SettingRoute,
    ...OscpvRoute,
    ...OscpvSettingRoute,
    ...ServiceRoute,
    {
        path: "/demo",
        element: <DemoPage />,
    },
]);
