import React from 'react';
import {Tabs} from 'antd';
import PersonalInfo from './PersonalInfo';
import ChangePassword from './ChangePassword';

import {HiUser} from 'react-icons/hi';
import {AiFillLock} from 'react-icons/ai';

import './index.style.less';

export default function UserProfile()
{
  return (
    <div className='user-profile-container'>
      <Tabs
        className='user-profile-tabs'
        // defaultActiveKey='1'
        tabPosition={'left'}
        items={[
          {
            label: <span className='user-profile-icon'>
              <HiUser className='icon' />
              <span>
                Персональна інформація
              </span>
            </span>,
            key: 'PersonalInfo',
            children: <PersonalInfo />,
          },
          {
            label: <span className='user-profile-icon'>
              <AiFillLock className='icon' />
              <span>
                Змінити пароль
              </span>
            </span>,
            key: 'ChangePassword',
            children: <ChangePassword />,
          }
        ]}
      >
      </Tabs>
    </div>
  );
};
