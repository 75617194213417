import { BsCardList, BsPersonVcard } from "react-icons/bs";

export const MenuItems = [
    {
        key: 'employee',
        label: 'Співробітники',
        icon: <BsPersonVcard size='20' />,
        path: '/setting',
    },
    {
        key: 'list',
        label: 'Довідники',
        icon: <BsCardList size='20' />,
        path: '#',
        children: [
            {
                key: 'car_type',
                label: 'Типи транспорта',
                path: '/setting/list/car-type'
            },
            {
                key: 'car_brand',
                label: 'Марки авто',
                path: '/setting/list/car-brand'
            },
            {
                key: 'car_model',
                label: 'Моделі авто',
                path: '/setting/list/car-model'
            },
            {
                key: 'fuel_type',
                label: 'Типи палива',
                path: '/setting/list/fuel-type'
            },
            {
                key: 'car_usage_reason',
                label: 'Підстави використання транспорту',
                path: '/setting/list/car-usage-reason'
            },
            {
                key: 'car_usage_type',
                label: 'Типи використання транспорту',
                path: '/setting/list/car-usage-type'
            },
            {
                key: 'design_restriction',
                label: 'Обмеження оформлення',
                path: '/setting/list/design-restriction'
            },
            {
                key: 'document_type',
                label: 'Тип документа',
                path: '/setting/list/document-type'
            },
            {
                key: 'exchange_rate',
                label: 'Курс валют',
                path: '/setting/list/exchange-rate'
            },
            {
                key: 'faq',
                label: 'FAQ',
                path: '/setting/list/faq'
            },
            {
                key: 'gender',
                label: 'Пол людини',
                path: '/setting/list/gender'
            },
            {
                key: 'insurance_product_benefit',
                label: 'Переваги страхових продуктів',
                path: '/setting/list/insurance-product-benefit'
            },
            {
                key: 'insurance_product',
                label: 'Страхові продукти',
                path: '/setting/list/insurance-product'
            },
            {
                key: 'partner',
                label: 'Партнери',
                path: '/setting/list/partner'
            },
            {
                key: 'partner_insurance_product_discount',
                label: 'Партнерські знижки страхових продуктів',
                path: '/setting/list/partner-insurance-product-discount'
            },
            {
                key: 'message_event',
                label: 'Події повідомлень',
                path: '/setting/list/message-event'
            },
            {
                key: 'message_mileage_balance',
                label: 'Повідомлення балансу пробігу',
                path: '/setting/list/message-mileage-balance'
            },
            {
                key: 'message_type',
                label: 'Тип повідомлення',
                path: '/setting/list/message-type'
            },
            {
                key: 'payment_type',
                label: 'Типи оплати',
                path: '/setting/list/payment-type'
            },
            {
                key: 'risk',
                label: 'Ризики',
                path: '/setting/list/risk'
            },
            {
                key: 'source',
                label: 'Джерела',
                path: '/setting/list/source'
            },
            {
                key: 'status',
                label: 'Статуси',
                path: '/setting/list/status'
            },
            {
                key: 'tariff_insurance_product',
                label: 'Таблиця тарифів страхових продуктів',
                path: '/setting/list/tariff-insurance-product'
            },
            {
                key: 'tariff_kilometer',
                label: 'Тариф кілометрів',
                path: '/setting/list/tariff-kilometer'
            },
            {
                key: 'tracking_system',
                label: 'Трекінг система',
                path: '/setting/list/tracking-system'
            },
            {
                key: 'verification_type',
                label: 'Тип верифікації',
                path: '/setting/list/verification-type'
            },
            {
                key: 'task_type',
                label: 'Типи завдань',
                path: '/setting/list/task-type'
            },
            {
                key: 'bonus-type',
                label: 'Типи бонусів',
                path: '/setting/list/bonus-type'
            },
            {
                key: 'bonus-transaction-type',
                label: 'Типи транзакцій бонусів',
                path: '/setting/list/bonus-transaction-type'
            },
            {
                key: 'bonus-rating-month',
                label: 'Мільбек',
                path: '/setting/list/bonus-rating-month'
            },
            {
                key: 'app-version',
                label: 'Версії мобільного додатка',
                path: '/setting/list/app-version'
            },
            {
                key: 'call-type',
                label: 'Типи дзвінків',
                path: '/setting/list/call-type'
            },
            {
                key: 'call-state',
                label: 'Стани дзвінків',
                path: '/setting/list/call-state'
            }
        ]
    }
];