import React from 'react';
import {Menu} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import SidebarMenuStore from './SidebarMenuStore';
import { observer } from "mobx-react-lite"
import { useEffect } from 'react';
import './index.less';

const SidebarMenu = ({items}) => {

  const routeMenu = items || [];
  
  const navigate = useNavigate();
  const location = useLocation();

  const getOpenAndSelectedKeys = (routeMenu) => {

    let openKeysDefault = [];
    let selectedKeysDefault = [];

    routeMenu.map(item => {
      if (item.children)
        item.children.map(child => {
          openKeysDefault.push(item.key);
          if(child.path && child.path === location.pathname)
          {
            selectedKeysDefault.push(child.key);
          }
        });
      else if(item.path && item.path === location.pathname)
        selectedKeysDefault.push(item.key);
    })

    return {openKeysDefault, selectedKeysDefault};
  }

  useEffect(() => {
    const {openKeysDefault, selectedKeysDefault} = getOpenAndSelectedKeys(routeMenu);
    SidebarMenuStore.setOpenKeys(openKeysDefault);
    SidebarMenuStore.setSelectedKeys(selectedKeysDefault);
  }, [location]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => SidebarMenuStore.openKeys.indexOf(key) === -1);
    SidebarMenuStore.setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <>
    <Menu
      theme='light'
      mode='inline'
      openKeys={SidebarMenuStore.openKeys}
      onOpenChange={onOpenChange}
      items={routeMenu}
      selectedKeys={SidebarMenuStore.selectedKeys}
      onClick={({item, key, keyPath, domEvent}) => {
        navigate(item.props.path);
      }}
    />
    </>
  );
};

export default observer(SidebarMenu);
