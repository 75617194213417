import React from 'react';

import { Badge, Divider } from 'antd';

import { FiSettings } from 'react-icons/fi';
import { GiExitDoor } from 'react-icons/gi';
import { AiOutlineNotification } from 'react-icons/ai';

import Notifications from '~/Core/Components/Notifications';

import '../index.less';

import { RouterDecorator } from '~/Core/Decorators/RouterDecorator';

@RouterDecorator
class Minibar extends React.Component
{
  constructor(){
    super();

    this.state = {
      taskCount: 0
    };

    this.notificationsRef = React.createRef();
  }

  componentDidMount(){
    // RequestClientHelper.get('sale/task').then(response => {
    //   if(response['hydra:totalItems']) 
    //     this.setState({taskCount: response['hydra:totalItems']});
    // });
  }

  render()
  {
    return <>
      <div className='bucket-minibar'>
        <div className='bucket-minibar-inner' style={{height: '100%'}}>
          <a className='bucket-minibar-logo' onClick={(e) => {
            this.props.router.navigate('/');
          }}>
            <img
              src={'/assets/images/logo.png'}
              alt='logo'
            />
          </a>
          
            <div className='bucket-minibar-main'>
              {/* <a
                style={{margin: '5px 0', color: 'white', width: 'auto'}}
                className='bucket-minibar-link'
                onClick={(e) => {
                  this.notificationsRef.current.setOpen(true);
                }}>
                <Badge size="small" count={4}>
                  <AiOutlineNotification style={{fontSize: '36px', color: '#000'}} />
                </Badge>
              </a> */}
            </div>
            <div className='bucket-minibar-setting' style={{marginBottom: '10px', color: 'white'}}>
              {/*<a
                className='bucket-minibar-link'
                onClick={(e) => {
                  localStorage.setItem('user', null);
                  localStorage.setItem('token', null);
                  this.props.router.navigate('/public/signin');
                }}>
                <GiExitDoor style={{color: '#000', fontSize: '22px'}} />
              </a>*/}
            </div>
        </div>
      </div>
      <Notifications ref={this.notificationsRef} />
    </>; 
  }
}

export default RouterDecorator(Minibar);
