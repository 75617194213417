import React from "react";

import { SmileOutlined } from '@ant-design/icons';
import { notification } from "antd";
import EmployeeStore from "~/Ship/Stores/EmployeeStore";
import BackendConfig from "~/Ship/Config/Backend";

import CounterStore from "~/Ship/Stores/CounterStore";

export class MercureBaseHandler extends React.Component
{
    componentDidMount()
    {
        const user = EmployeeStore.me;

        if(!user)
            return;
        
        if( !BackendConfig?.MERCURE_URL )
            return;

        const url = new URL(BackendConfig.MERCURE_URL);

        if(!window.wlEventHandlers)
            window.wlEventHandlers = {};

        if(!window.wlEventHandlers.notifications)
        {
            url.searchParams.append('topic', `notification/${user.id}`);
            url.searchParams.append('topic', `notification/all`);
            url.searchParams.append('topic', `counters`);

            const eventSource = new EventSource(url);
    
            eventSource.onmessage = e => {
                const result = JSON.parse(e.data);
                const message = result.message;
                const link = result.link ? result.link : null;

                switch( result?.type ) {
                    case 'counters':
                        CounterStore.setCounters( result.data );
                        break;
                    default:

                        notification.open({
                            message: false,
                            description: <>
                                <p>{message}</p>
                                {link && <a href={link.href}>{link.title}</a>}
                            </>,
                            icon: (
                            <SmileOutlined
                                style={{
                                color: 'var(--primaryColor)',
                                }}
                            />
                            ),
                        });

                        break;
                }                
            };
            
            window.addEventListener("beforeunload", function(e){
                eventSource.close();
                return null;
            }, false);

            window.wlEventHandlers.notifications = true;
        }
    }

    render() { }
}