import { MdOutlinePayment } from "react-icons/md";
import { GiPoliceCar } from "react-icons/gi";

export const MenuItems = [
    {
        key: "payment_list",
        label: "Оплати послуг",
        icon: <MdOutlinePayment size="20" />,
        path: "#",
        children: [
            {
                key: 'penalty',
                label: 'Штрафи',
                icon: <GiPoliceCar size="20" />,
                path: '/services/payment/penalty',
            },
        ],
    },
];
