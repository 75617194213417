import React from 'react';
import { ConfigProvider, theme } from 'antd';

import ukUA from 'antd/es/locale/uk_UA';

import 'antd/dist/reset.css';
import 'animate.css';

const { useToken } = theme;

function FillStyleVars({children}) {
  const tokenData = useToken();
  let root = document.documentElement;
  Object.keys(tokenData.token).map(key => root.style.setProperty('--' + key, tokenData.token[key]));
  return children;
}

export const BaseLayout = ({children}) => {

    const defaultData = {
        borderRadius: 5,
        colorPrimary: "#F7C323",
        fontSize: 14
    };
    
    return <ConfigProvider 
        locale={ ukUA }
        theme={{ token: defaultData }}
    >
        <FillStyleVars>{children}</FillStyleVars>
    </ConfigProvider>
}