import React, { useState } from 'react';
import { Avatar, Drawer, Dropdown } from 'antd';
import { useNavigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';

import { FaChevronDown, FaUserAlt } from 'react-icons/fa';
import { AiOutlineExport } from "react-icons/ai";

import EmployeeStore from "~/Ship/Stores/EmployeeStore";

import ProfileComponent from "~/Containers/EasyPeasySection/ProfileContainer/Components/ProfileComponent";

import Config from "~/Ship/Config/Backend";

import './index.less';
import { toJS } from 'mobx';

const UserInfo = observer(({ collapsed }) => {

  const user = toJS(EmployeeStore.me);

  const navigate = useNavigate();

  const getUserAvatar = () => {
    if (user.name && user?.lastName) {
      return user?.name.charAt(0).toUpperCase() + ' ' + user?.lastName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  if(!user)
    return;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const items = [
    {key: 'profile', label: <a href="#" onClick={() => {setDrawerOpen(true)}}><FaUserAlt /> Профiль</a>},
    {key: 'logout', label: <a href="#" onClick={() => {
        localStorage.setItem('user', null);
        localStorage.setItem('token', null);
        localStorage.setItem('redirect', '');
        navigate('/public/signin');
    }}><AiOutlineExport /> Вийти</a>}
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
          color: '#010101',
        }}
        className='cr-user-info cr-user-info-hasColor'>
        <Dropdown
          className='user-profile-dropdown'
          menu={{items}}
          trigger={['click']}
          placement='bottomRight'
          overlayStyle={{
            zIndex: 1052,
            minWidth: 150,
          }}
        >
          <a className='cr-user-info-inner ant-dropdown-link'>
            {user?.photo ? (
              <Avatar className='cr-user-info-avatar' src={Config.API_URL + user?.photo} />
            ) : (
              <Avatar className='cr-user-info-avatar'>
                {getUserAvatar()}
              </Avatar>
            )}
            {!collapsed && <span className='cr-user-info-content'>
              <span className='cr-user-name-info'>
                <h3
                  className='cr-user-name text-truncate'>
                  {user.name + ' ' + user.lastName}
                </h3>
                <span className='cr-user-arrow'>
                  <FaChevronDown />
                </span>
              </span>
              <span className='cr-user-designation text-truncate'>
                { user.position ? user.position : '' }
              </span>
            </span>}
          </a>
        </Dropdown>
      </div>
      <Drawer open={drawerOpen} width={1200} title='Мій профіль' onClose={() => setDrawerOpen(false)}>
        <ProfileComponent />
      </Drawer>
    </>
  );
});

export default UserInfo;