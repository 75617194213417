import React from "react";
import RequestClientHelper from "~/Core/Helpers/RequestClientHelper";
import CounterStore from "~/Ship/Stores/CounterStore";

export class CoutersHandler extends React.Component {
    
    loadCounters = async () => {
        const result = await RequestClientHelper.get('/api/services/get-counters');
        CounterStore.setTaskCreated( result.taskCreated );
    }

    componentDidMount() {
        this.loadCounters();
    }

    render() {}
}