import { Avatar, Badge, Drawer, List, Tabs } from "antd";
import React from "react";
import RequestClientHelper from '~/Core/Helpers/RequestClientHelper';
import { Skeleton } from 'antd';

export default class Notifications extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            open: false,
            loaded: false,
            data: []
        }
    }

    setOpen = (open) => {
        
        if(open)
        {
            RequestClientHelper.get('company/employee/notifications', {}, {
                headers: {
                    "Preload": "hydra:member/*/recipient"
                }
            })
                .then((body) => {
                    this.setState({ data: body['hydra:member'], loaded: true }, () => {
                        
                    });
                });
        }
            this.setState({ open });
    }

    componentDidMount = () => {
        
    }

    render() {
        return <Drawer open={this.state.open} onClose={() => {this.setOpen(false)}} title={'Панель повідомлень'} destroyOnClose={true}>
            {!this.state.loaded && <Skeleton active />}
            {this.state.loaded && <List dataSource={this.state.data}
                    renderItem={(item) => (
                        <List.Item key={item.id} style={{margin: 0, padding: '10px'}}>
                            <List.Item.Meta
                            avatar={false}
                            title={false}
                            description={item.message}
                            />
                        </List.Item>
                    )}
            />}
        </Drawer>
    }
}