import React from "react";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const MyTaskPage = React.lazy(() => import("~/Containers/EasyPeasySection/TaskContainer/Pages/MyTaskPage"));
const CalculationPage = React.lazy(() => import("~/Containers/OscpvSection/CalculationContainer/Pages/CalculationPage"));
const ContractPage = React.lazy(() => import("~/Containers/OscpvSection/ContractContainer/Pages/ContractPage"));
const PaymentPage = React.lazy(() => import("~/Containers/OscpvSection/PaymentContainer/Pages/PaymentPage"));
const ClientPage = React.lazy(() => import("~/Containers/OscpvSection/ClientContainer/Pages/ClientPage"));
const ReminderPage = React.lazy(() => import("~/Containers/OscpvSection/ReminderContainer/Pages/ReminderPage"));
const CarPage = React.lazy(() => import("~/Containers/OscpvSection/CarContainer/Pages/CarPage"));
const CallPage = React.lazy(() => import("~/Containers/EasyPeasySection/TelephonyContainer/Pages/CallPage"));
const InsuranceCasePage = React.lazy(() => import("~/Containers/EasyPeasySection/InsuranceCaseContainer/Pages/InsuranceCasePage"));
const BonusTransactionPage = React.lazy(() => import("~/Containers/EasyPeasySection/BonusSystemContainer/Pages/BonusTransactionPage"));

const IndePage = React.lazy(() => import("../Pages/IndePage"));

import SetSectionMenuTask from "../Tasks/SetSectionMenuTask";

export const OscpvRoute = [
    {
        path: '/oscpv',
        element: <DefaultLayout />,
        loader: () => {
            SetSectionMenuTask.run();
            return null;
        },
        children: [
            { index: true, element: <React.Suspense fallback={<>...</>}><IndePage /></React.Suspense> },
            { path: 'task', element: <React.Suspense fallback={<>...</>}><MyTaskPage /></React.Suspense> },
            { path: 'calculation', element: <React.Suspense fallback={<>...</>}><CalculationPage /></React.Suspense> },
            { path: 'contract', element: <React.Suspense fallback={<>...</>}><ContractPage /></React.Suspense> },
            { path: 'payment', element: <React.Suspense fallback={<>...</>}><PaymentPage /></React.Suspense> },
            { path: 'client', element: <React.Suspense fallback={<>...</>}><ClientPage /></React.Suspense> },
            { path: 'client/:mode/:id', element: <React.Suspense fallback={<>...</>}><ClientPage /></React.Suspense> },
            { path: 'reminder', element: <React.Suspense fallback={<>...</>}><ReminderPage /></React.Suspense> },
            { path: 'car', element: <React.Suspense fallback={<>...</>}><CarPage /></React.Suspense> },
            { path: 'insurance-case', element: <React.Suspense fallback={<>...</>}><InsuranceCasePage /></React.Suspense> },
            { path: 'calls', element: <React.Suspense fallback={<>...</>}><CallPage /></React.Suspense> },
            { path: 'bonuses', element: <React.Suspense fallback={<>...</>}><BonusTransactionPage /></React.Suspense> }
        ]
    }
];

