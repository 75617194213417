import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import ModalComponent from './Components/ModalComponent';

import { BaseLayout } from '../BaseLayout';

import './index.modules.less';

export const AuthLayout = ({children}) => <Layout className='auth-layout'>
    <BaseLayout>
        <ModalComponent>
            <Outlet />
        </ModalComponent>
    </BaseLayout>
</Layout>