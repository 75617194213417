import { makeAutoObservable } from "mobx";

class CounterStore {

    taskCreated = 0;

    constructor() {
        makeAutoObservable(this)
    }

    setTaskCreated( count ) {

        this.taskCreated = count;
    }

    setCounters( counters ) {

        if ( typeof counters?.taskCreated === 'number' )
            this.taskCreated = counters.taskCreated;
    }
}

export default new CounterStore;