import React from 'react';

import {Button, Col, Form, Input, notification, Row} from 'antd';
import RequestClient from '~/Core/Helpers/RequestClientHelper';

const ChangePassword = () => {
  const onFinish = (values) => {

    RequestClient.post('/api/company/employee/change_password', values).then(() => {
      notification.success({
        message: 'Оновлення інформації',
        description: 'Пароль успішно оновлено!',
        placement: 'top',
      });
    });
  };

  const onFinishFailed = (errorInfo) => {

  };

  return (
    <Form
      className='user-profile-form'
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <h3 className='user-profile-form-title'>
        Змінити пароль
      </h3>
      <Row gutter={16}>
        <Col md={24}>
          <Form.Item
            name='oldPassword'
            rules={[
              {required: true, message: 'Будь ласка, введіть свій старий пароль!'},
            ]}>
            <Input.Password placeholder='Старий пароль' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='password'
            rules={[
              {required: true, message: 'Будь ласка, введіть свій новий пароль!'},
            ]}>
            <Input.Password placeholder='Новий пароль' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='password_confirmation'
            rules={[
              {required: true, message: 'Будь ласка, введіть свій пароль підтвердження!'},
              ({getFieldValue}) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'Пароль та підтвердження, який ви ввели, не збігаються!',
                  );
                },
              }),
            ]}>
            <Input.Password placeholder='Підтвердити новий пароль' />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item shouldUpdate className='user-profile-group-btn'>
            <Button type='primary' htmlType='submit'>
              Зберегти зміни
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangePassword;
