import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, Divider } from 'antd';
// import { IntlProvider } from 'react-intl';

import { AiOutlineMenu } from 'react-icons/ai';

import { GlobalPlaceComponent } from '~/Core/Components/GlobalPlace';
import { ProtectedProvider } from '~/Core/Providers/ProtectedProvider';

import EmployeeStore from "~/Ship/Stores/EmployeeStore";

import Minibar from './Minibar';
import UserInfo from './UserInfo';

import { MercureBaseHandler } from "./Handlers/MercureBaseHandler";
import { CoutersHandler } from './Handlers/CountersHandler'; 

import { MESSAGES } from '~/Ship/Locale/Messages';
import { LOCALES } from '~/Ship/Locale/Locales';

import { BaseLayout } from '../BaseLayout';

const { Header, Footer, Sider, Content } = Layout;

export const DefaultLayout = React.memo(( props ) => {

  let user = localStorage.getItem('user');
  const [collapsed, setCollapsed] = useState(false);

  if( user && user !== 'undefined' ) {
    user = JSON.parse(localStorage.getItem('user'));
    EmployeeStore.setMe( user );
  }
  // TODO: install react-intl
  // "react-intl": "^6.2.10",
  // <IntlProvider messages={MESSAGES[LOCALES.UKRAINIAN]} locale={LOCALES.UKRAINIAN} defaultLocale={LOCALES.UKRAINIAN}></IntlProvider>
  return (
    <ProtectedProvider>
      <BaseLayout>
        <Layout style={{minHeight: '100vh'}}>
          <Minibar />
          {(user?.roles.includes('ROLE_ADMIN') || user?.roles.includes('ROLE_READONLY')) && <Sider theme='light' trigger={null} collapsible collapsed={collapsed} width={250}>
            <UserInfo hasColor collapsed={collapsed} />
            <Divider style={{backgroundColor: 'rgba(234, 234, 234, 0.3)', margin: '0'}}  />
            <GlobalPlaceComponent name="place-1" />
          </Sider>}
          {user?.roles.includes('ROLE_INSTALLER') && <Sider width={1} theme='light' />}
          <Layout>
            {/* {(user?.roles.includes('ROLE_ADMIN') || user?.roles.includes('ROLE_READONLY')) && <Header className='header'>
              <a className='trigger' onClick={() => setCollapsed(!collapsed)}>
                <AiOutlineMenu />
              </a>
            </Header>} */}
            <Content style={{padding: '20px 25px'}}>
              <Outlet />
            </Content>
            <Footer style={{color: 'rgb(181, 181, 181)', padding: '10px 25px 25px 25px'}}>
              <span>&copy;Easy Peasy Insurtech, {(new Date()).getFullYear()}</span>
            </Footer>
          </Layout>
        </Layout>
        <MercureBaseHandler />
        <CoutersHandler />
      </BaseLayout>
    </ProtectedProvider>
  );
});