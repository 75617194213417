import { AiOutlineCalculator } from "react-icons/ai";
import { IoMdContacts } from "react-icons/io";
import { IoDocumentTextOutline, IoHardwareChipOutline } from "react-icons/io5";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { MdOutlinePayment, MdOutlinePhoneInTalk } from "react-icons/md";
import { AiFillCar, AiOutlineSetting } from "react-icons/ai";
import { BsBriefcase, BsCurrencyDollar } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { RiRoadMapLine } from "react-icons/ri";
import { observer } from "mobx-react-lite";
import { Badge } from "antd";

import CounterStore from "~/Ship/Stores/CounterStore";

const TaskLabel = observer(() => <>Задачі&nbsp;&nbsp;<Badge count={CounterStore.taskCreated} />&nbsp;</>);

export const MenuItems = [
    {
        key: 'task',
        label: <TaskLabel />,
        icon: <FaTasks size='20' />,
        path: '/easypeasy/task'
    },
    {
        key: 'calculation',
        label: 'Розрахунки',
        icon: <AiOutlineCalculator size='20' />,
        path: '/easypeasy/calculation'
    },
    {
        key: 'contract',
        label: 'Договори',
        icon: <IoDocumentTextOutline size='20' />,
        path: '/easypeasy/contract'
    },
    {
        key: 'agreement',
        label: 'Додаткові угоди',
        icon: <HiOutlineDocumentDuplicate size='20' />,
        path: '/easypeasy/agreement'
    },
    {
        key: 'payment',
        label: 'Оплати',
        icon: <MdOutlinePayment size='20' />,
        path: '/easypeasy/payment'
    },
    {
        key: 'client',
        label: 'Клієнти',
        icon: <IoMdContacts size='20' />,
        path: '/easypeasy/client'
    },
    {
        key: 'car',
        label: 'Автомобілі',
        icon: <AiFillCar size='20' />,
        path: '/easypeasy/car'
    },
    {
        key: 'equipment',
        label: 'Встановлення додаткового обладнання',
        icon: <IoHardwareChipOutline size='20' />,
        path: '/easypeasy/equipment-application'
    },
    {
        key: 'travel',
        label: 'Поїздки',
        icon: <RiRoadMapLine size='20' />,
        path: '/easypeasy/travel'
    },
    {
        key: 'insurance_case',
        label: 'Страхові випадки',
        icon: <BsBriefcase size='20' />,
        path: '/easypeasy/insurance-case'
    },
    {
        key: 'calls',
        label: 'Дзвінки',
        icon: <MdOutlinePhoneInTalk size='20' />,
        path: '/easypeasy/calls'
    },
    {
        key: 'bonuses',
        label: 'Бонуси',
        icon: <BsCurrencyDollar size='20' />,
        path: '/easypeasy/bonuses'
    },
    {
        key: 'setting',
        label: 'Системні налаштування',
        icon: <AiOutlineSetting size='20' />,
        path: '#',
        children: [
            {
                key: 'additional_car',
                label: 'Додаткові авто',
                path: '/easypeasy/setting/additional-car'
            },
            {
                key: 'special_condition_car',
                label: 'Особливі умови авто',
                path: '/easypeasy/setting/special-condition-car'
            },
            {
                key: 'individual_cost_car',
                label: 'Індивідуальна вартість авто',
                path: '/easypeasy/setting/individual-cost-car'
            },
            {
                key: 'service_event_log',
                label: 'Журнал подій',
                path: '/easypeasy/setting/event-log'
            },
        ]
    }
];