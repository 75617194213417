import { makeAutoObservable, toJS } from 'mobx';

class GlobalPlaceStore {

    places = {}

    constructor() {
        makeAutoObservable(this);
    }

    addToPlace(placeName, childrenName, children) {
        
        if(!placeName || !children || !childrenName)
            return;

        if(!this.places[placeName])
            this.places[placeName] = {};

        if(!this.places[placeName][childrenName])
            this.places[placeName][childrenName] = {};

        this.places[placeName][childrenName] = children;
    }

    getPlace(placeName) {
        if(this.places[placeName])
            return Object.values(this.places[placeName]).map(item => toJS(item));
            
        return [];
    }
}

export default new GlobalPlaceStore();