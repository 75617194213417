import { Card } from 'antd';

import './index.less';

const ModalComponent = ({children}) => {
  return (
    <div className='auth-wrap' key={'wrap'} style={{minHeight: '100vh'}}>
        <Card className='auth-card'>
          <div className='auth-main-content'>
            <div className='auth-main-logo'>
              <img alt="Easy Peasy Insurtech" src="/assets/images/logo.svg" data-v-36dc5805="" width="120" height="46" />
            </div>
            {children}
          </div>
          <div className='auth-wel-action'>
            <div className='auth-wel-content'>
              <h2>Ласкаво просимо до Easy Peasy!</h2>
              <p>Easy Peasy – це місце для розвитку вашого бізнесу. Все, що вам потрібно, в одному місці.</p>
            </div>
          </div>
        </Card>
      </div>
  );
};

export default ModalComponent;