import React from "react";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const PenaltyPage = React.lazy(() =>
    import("~/Containers/ServiceSection/PaymentContainer/Pages/PenaltyPage")
);

const IndexPage = React.lazy(() => import("../Pages/IndexPage"));

import SetSectionMenuTask from "../Tasks/SetSectionMenuTask";

export const ServiceRoute = [
    {
        path: "/services",
        element: <DefaultLayout />,
        loader: () => {
            SetSectionMenuTask.run();
            return null;
        },
        children: [
            {
                path: '#',
                element: (
                    <React.Suspense fallback={<>...</>}>
                        <IndexPage />
                    </React.Suspense>
                ),
            },
            {
                path: "payment",
                children: [
                    {
                        path: "penalty",
                        element: (
                            <React.Suspense fallback={<>...</>}>
                                <PenaltyPage />
                            </React.Suspense>
                        ),
                    },
                ],
            },
        ],
    },
];
