import qs from 'query-string';
import backendConfig from '~/Ship/Config/Backend';
// import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
export const DOMAIN = backendConfig.API_URL;

class RequestClientHelper
{
    currentLocale = 'Uk';

    constructor(domain)
    {
        this.domain = domain;
        this.token = false;
    }

    setLocale( locale ) {
        this.currentLocale = locale;
        return this;
    }

    async perform(url, data, config) {

        let headers = {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/ld+json',
            'X-LOCALE': this.currentLocale,
            'X-AUTH-EMPLOYEE': true
            // 'Access-Control-Allow-Origin': '*'
        }

        if(config?.method && config.method === 'PATCH')
            headers['Content-Type'] = 'application/merge-patch+json';

        const token = localStorage.getItem('token');

        if(token && token !== 'null' && token !== 'undefined')
            headers.Authorization = `Bearer ${token}`;

        if( config?.headers )
        {
            headers = { ...headers, ...config.headers };
            delete config.headers;
        }

        try
        {
            const request = await fetch(`${this.domain}${url}`, {
                ...config,
                body: data ? JSON.stringify(data) : null,
                headers: headers
            })

            if( request.status === 500 )
            {
                const response = await request.json();

                if(
                    response['@type'] != undefined
                    && response['@type'] == 'hydra:Error'
                )
                {
                    message.open({
                        type: 'error',
                        content: response['hydra:description'],
                    })
                }
                return;
            }

            if( 
                request.status === 401
                && headers?.Authorization
            )
            {

                // const navigate = useNavigate();
                // navigate("/public/signin");
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user');
                localStorage.removeItem('redirect');
                
                location.href = '/public/signin';
                return;
                const refresh_token = localStorage.getItem('refresh_token');
                if(refresh_token && refresh_token !== 'null' && refresh_token !== 'undefined')
                {
                    const request = await fetch(`${this.domain}/public/refresh`, {
                        method: 'POST',
                        body: JSON.stringify({refresh_token}),
                        headers: {'Content-Type': 'application/json', 'Authorization': headers?.Authorization}
                    })
                }
            }

            if(config?.method && config.method === 'DELETE')
                return;
            else
                return await request.json();
        }
        catch(error)
        {
            
        }
    }

    async get( path, searchParams = {}, config ) {
        return await this.perform( `${path}?${qs.stringify(searchParams)}`, null, config );
    }

    async post(path, payload) {
        return await this.perform(path, payload, {
            method: 'POST'
        });
    }

    async put(path, payload) {
        return await this.perform(path, payload, {
            method: 'PUT'
        });
    }

    async patch(path, payload) {
        return await this.perform(path, payload, {
            method: 'PATCH'
        });
    }

    async delete(path) {
        return await this.perform(path, null, {
            method: 'DELETE'
        });
    }
}

export default new RequestClientHelper(DOMAIN);