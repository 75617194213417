import { makeAutoObservable } from "mobx";

class EmployeeStore {

    me = {};

    constructor() {
        makeAutoObservable(this)
    }

    setMe( me ) {
        localStorage.setItem('user', JSON.stringify(me))
        this.me = me;
    }
}

export default new EmployeeStore;