import { BsCardList, BsPersonVcard } from "react-icons/bs";

export const MenuItems = [
    {
        key: 'employee',
        label: 'Співробітники',
        icon: <BsPersonVcard size='20' />,
        path: '/oscpv-setting',
    },
    {
        key: 'list',
        label: 'Довідники',
        icon: <BsCardList size='20' />,
        path: '#',
        children: [
            {
                key: 'city',
                label: 'Міста',
                path: '/oscpv-setting/list/city'
            },
            {
                key: 'privilege',
                label: 'Пільги',
                path: '/oscpv-setting/list/privilege'
            },
            {
                key: 'car-brand',
                label: 'Марки авто',
                path: '/oscpv-setting/list/car-brand'
            },
            {
                key: 'car-model',
                label: 'Моделі авто',
                path: '/oscpv-setting/list/car-model'
            },
            {
                key: 'car-type',
                label: 'Об\'єми двигуна',
                path: '/oscpv-setting/list/car-type'
            },
            {
                key: 'franchise',
                label: 'Франшизи',
                path: '/oscpv-setting/list/franchise'
            },
            {
                key: 'voluntary-insurance',
                label: 'Добровільне додаткове страхування',
                path: '/oscpv-setting/list/voluntary-insurance'
            }
        ]
    }
];