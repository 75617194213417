import React from "react";
import { DefaultLayout } from "~/Ship/Layouts/DefaultLayout";

const CalculationPage = React.lazy(() => import("~/Containers/EasyPeasySection/CalculationContainer/Pages/CalculationPage"));
const ContractPage = React.lazy(() => import("~/Containers/EasyPeasySection/ContractContainer/Pages/ContractPage"));
const AgreementPage = React.lazy(() => import("~/Containers/EasyPeasySection/ContractContainer/Pages/AgreementPage"));
const PaymentPage = React.lazy(() => import("~/Containers/EasyPeasySection/PaymentContainer/Pages/PaymentPage"));
const ClientPage = React.lazy(() => import("~/Containers/EasyPeasySection/ClientContainer/Pages/ClientPage"));
const CarPage = React.lazy(() => import("~/Containers/EasyPeasySection/CarContainer/Pages/CarPage"));
const ApplicationPage = React.lazy(() => import("~/Containers/EasyPeasySection/EquipmentContainer/Pages/ApplicationPage"));
const InsuranceCasePage = React.lazy(() => import("~/Containers/EasyPeasySection/InsuranceCaseContainer/Pages/InsuranceCasePage"));
const MyTaskPage = React.lazy(() => import("~/Containers/EasyPeasySection/TaskContainer/Pages/MyTaskPage"));
const CallPage = React.lazy(() => import("~/Containers/EasyPeasySection/TelephonyContainer/Pages/CallPage"));
const BonusTransactionPage = React.lazy(() => import("~/Containers/EasyPeasySection/BonusSystemContainer/Pages/BonusTransactionPage"));
const TravelPage = React.lazy(() => import("~/Containers/EasyPeasySection/TravelContainer/Pages/TravelPage"));

const AdditionalCarPage = React.lazy(() => import("../../SettingContainer/Pages/AdditionalCarPage"));
const SpecialConditionCarPage = React.lazy(() => import("../../SettingContainer/Pages/SpecialConditionCarPage"));
const IndividualCostCarPage = React.lazy(() => import("../../SettingContainer/Pages/IndividualCostCarPage"));
const EventLogPage = React.lazy(() => import("../../SettingContainer/Pages/EventLogPage"));

const DashboardPage = React.lazy(() => import("../Pages/DashboardPage"));

import SetSectionMenuTask from "../Tasks/SetSectionMenuTask";

export const EasyPeasyRoute = [
    {
        path: '/easypeasy',
        element: <DefaultLayout />,
        loader: () => {
            SetSectionMenuTask.run();
            return null;
        },
        children: [
            { index: true, element: <React.Suspense fallback={<>...</>}><DashboardPage /></React.Suspense> },
            { path: 'task', element: <React.Suspense fallback={<>...</>}><MyTaskPage /></React.Suspense> },
            { path: 'calculation', element: <React.Suspense fallback={<>...</>}><CalculationPage /></React.Suspense> },
            { path: 'contract', element: <React.Suspense fallback={<>...</>}><ContractPage /></React.Suspense> },
            { path: 'agreement', element: <React.Suspense fallback={<>...</>}><AgreementPage /></React.Suspense> },
            { path: 'payment', element: <React.Suspense fallback={<>...</>}><PaymentPage /></React.Suspense> },
            { path: 'client', element: <React.Suspense fallback={<>...</>}><ClientPage /></React.Suspense> },
            { path: 'client/:mode/:id', element: <React.Suspense fallback={<>...</>}><ClientPage /></React.Suspense> },
            { path: 'car', element: <React.Suspense fallback={<>...</>}><CarPage /></React.Suspense> },
            { path: 'equipment-application', element: <React.Suspense fallback={<>...</>}><ApplicationPage /></React.Suspense> },
            { path: 'travel', element: <React.Suspense fallback={<>...</>}><TravelPage /></React.Suspense> },
            { path: 'insurance-case', element: <React.Suspense fallback={<>...</>}><InsuranceCasePage /></React.Suspense> },
            { path: 'calls', element: <React.Suspense fallback={<>...</>}><CallPage /></React.Suspense> },
            { path: 'bonuses', element: <React.Suspense fallback={<>...</>}><BonusTransactionPage /></React.Suspense> },
            { path: 'setting', children: [
                { path: 'additional-car', element: <React.Suspense fallback={<>...</>}><AdditionalCarPage /></React.Suspense> },
                { path: 'special-condition-car', element: <React.Suspense fallback={<>...</>}><SpecialConditionCarPage /></React.Suspense> },
                { path: 'individual-cost-car', element: <React.Suspense fallback={<>...</>}><IndividualCostCarPage /></React.Suspense> },
                { path: 'event-log', element: <React.Suspense fallback={<>...</>}><EventLogPage /></React.Suspense> }
            ] }
        ]
    }
];

