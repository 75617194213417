import { Layout } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

const headerStyle = {
  backgroundColor: '#FFFFFF',
  height: '72px'
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
};
const siderStyle = {
  textAlign: 'center',
  margin: '0 16px'

};
const footerStyle = {
  textAlign: 'center',
};

export const MainLayout = () => {
    return <Layout style={{minHeight: '100vh', backgroundColor: '#F3F6F4'}}>
        <Header style={headerStyle}>Header</Header>
        <Layout style={{marginTop: '24px'}}>
            <Sider style={siderStyle}>Sider</Sider>
            <Content style={contentStyle}>Content</Content>
        </Layout>
        <Footer style={footerStyle}>Footer</Footer>
    </Layout>
}