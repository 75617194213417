import React from 'react';
import { observer } from 'mobx-react-lite';

import GlobalPlace from '~/Core/Stores/GlobalPlaceStore';
import { toJS } from 'mobx';

export const GlobalPlaceComponent = ({name}) => {

    if(name === undefined)
        return;

    const GlobalPlaceObserv = observer(() => {

        const places = GlobalPlace.getPlace(name);

        if(Array.isArray(places) && places.length > 0)
            return places.map((place, index) => <React.Fragment key={`${name}-${index.toString()}`}>{place}</React.Fragment>);

        return '';
    });

    return <GlobalPlaceObserv />;
};